<template>
    <div class="page" id="reorganize">
        <div class="bg-white" style="margin-bottom: 10px">
            <div class="tabsCls">
                <el-tabs size="small" v-model="selectTab" @tab-click="handleClick">
                    <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.name"
                                 :name="item.value"></el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <div v-show="selectTab == 1">
            <el-form class="query-form"
                     @keyup.enter.native="getDataList(1,1)"
                     size="small" ref="inputForm" :inline="true" :model="inputForm"
                     label-width="100px">
                <el-form-item label="出库原因" prop="reason">
                    <el-select
                            v-model="inputForm.reason"
                            clearable
                            placeholder="请选择出库原因"
                            style="width: 100%"
                    >
                        <el-option
                                v-for="item in this.$dictUtils.getDictList('outbound_reason')"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="出库状态" prop="storeState">
                    <el-select
                            v-model="inputForm.storeState"
                            clearable
                            placeholder="请选择出库状态"
                            style="width: 100%"
                    >
                        <el-option
                                v-for="item in storeStateList"
                                :key="item.state"
                                :label="item.stateName"
                                :value="item.state"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="申请状态" prop="applicationState">
                    <el-select
                            v-model="inputForm.applicationState"
                            clearable
                            placeholder="请选择申请状态"
                            style="width: 100%"
                    >
                        <el-option
                                v-for="item in applicationStateList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="审核状态" prop="reviewState">
                    <el-select
                            v-model="inputForm.reviewState"
                            clearable
                            placeholder="请选择审核状态"
                            style="width: 100%"
                    >
                        <el-option
                                v-for="item in reviewStateList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="出库时间" prop="outDate">
                    <el-date-picker
                            v-model="inputForm.outDate"
                            type="daterange"
                            clearable
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="具体原因" prop="specificReasons">
                    <el-input v-model.trim="inputForm.specificReasons"
                              placeholder="请输入具体原因(限50字)"
                              maxlength="50"
                              clearable>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button size="small" type="primary" icon="el-icon-search" @click="getDataList(1,1)">查询
                    </el-button>
                    <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置</el-button>
                </el-form-item>
            </el-form>

            <div class="bg-white">
                <div class="text_right">
                    <el-button size="small" v-show="hasPermissionButton(`collection:business:${typePage}:batchExpor`)"
                               @click="exportData(1)">
                        <i class="icon-piliangdaochu iconfont buIcon"/>批量导出
                    </el-button>
                </div>
                <el-table
                        :data="dataList"
                        size="small"
                        v-loading="loading"
                        ref="multipleTable1"
                        height="calc(100vh - 385px)"
                        class="table"
                        :row-key="'id'"
                        :header-cell-style="{background:'#F9DFDF'}"
                        @selection-change="selectionChangeHandle"
                >
                    <el-table-column :reserve-selection="true" type="selection" width="50"/>
                    <el-table-column prop="reason" label="出库原因" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ $dictUtils.getDictLabel("outbound_reason", scope.row.reason, '-') }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="applicantName" label="出库申请人" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="specificReasons" label="具体原因" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="collectionNum" label="藏品种数" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="storeState" label="出库状态" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ getlistSelet(scope.row.storeState, storeStateList, 'state', 'stateName') }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="applicationState" label="申请状态" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ getlistSelet(scope.row.applicationState, applicationStateList, 'value', 'name') }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="reviewState" label="审核状态" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{
                            scope.row.reviewState == 0 ? '审核中' : scope.row.reviewState == 1 ? '审核驳回' : '审核通过'
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="submitDate" label="提交时间" width="100px">
                        <template slot-scope="scope">
                            {{ scope.row.submitDate | formatDate }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="operationDate" label="出库时间" width="100px">
                        <template slot-scope="scope">
                            {{ scope.row.operationDate | formatDate('YYYY-MM-DD') }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="preDate" label="预入库时间" width="100px"
                                     show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.preDate | formatDate('YYYY-MM-DD') }}
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button style="margin-bottom: 10px" size="mini" type="text"
                                       @click="addData(1, scope.row,scope.$index,3)"
                                       v-show="hasPermissionButton(`collection:business:${typePage}:detail`)">
                                详情
                            </el-button>
                            <el-button style="margin-bottom: 10px" size="mini" type="text"
                                       @click="record(scope.row)"
                                       v-show="hasPermissionButton(`collection:business:${typePage}:record`)">
                                审批记录
                            </el-button>
                            <el-button style="margin-bottom: 10px" size="mini" type="text"
                                       @click="deleteDate(scope.row)"
                                       v-show="hasPermissionButton(`collection:business:${typePage}:delete`)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="text_center">
                    <el-pagination
                            @size-change="sizeChangeHandle"
                            @current-change="currentChangeHandle"
                            :current-page="pageNo"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="pageSize"
                            :total="total"
                            background
                            layout="total, sizes, prev, pager, next, jumper"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <div v-show="selectTab == 2">
            <el-row :gutter="10">
              <el-col :span="showList ? 4 : 1" :class="['p_r', showList ? '' : 'el-col-unfold','leftHeighe']">
                <div :class="['unfoldClassifyBtn', showList ? 'el-icon-arrow-left':'el-icon-arrow-right']"
                     @click="showList = !showList"></div>
                    <div class="bg-white leftHeighe" v-show="showList">
                        <el-descriptions title="藏品分类"></el-descriptions>
                        <div class="flex_b_c">
                            <el-input size="small" placeholder="输入关键字进行过滤" clearable v-model="filterText"
                                      @input="filterTreeData"></el-input>
                        </div>
                        <el-tree v-loading="treeLoading" node-key="id" ref="tree" :data="treeData" class="treeDateCls"
                                 show-checkbox :filter-node-method="filterNode"
                                 :props="defaultProps" @check="selRecord">
                            <div slot-scope="{ node, data }">
                                <el-tooltip class="item" effect="dark" :content="node.label" placement="top-start">
                                    <div class="omit">{{ node.label }}</div>
                                </el-tooltip>
                            </div>
                        </el-tree>
                    </div>
                </el-col>
              <el-col :span="showList ? 20 : 24" :class="[showList ? '' : 'el-col-unfold','leftHeighe']">
                    <div class="text_center noData itembaf" v-show="!archivesBasicDataId.length">请选择左侧藏品分类
                    </div>
                    <div v-show="archivesBasicDataId.length">
                        <el-form class="query-form searchForm"
                                 @keyup.enter.native="getDataList(1,1)"
                                 size="small" ref="inputForm2" :model="inputForm2"
                                 label-width="100px">
                            <el-form-item label="账目归属" prop="accountType">
                                <el-select
                                        v-model="inputForm2.accountType"
                                        clearable
                                        placeholder="请选择账目归属"
                                        style="width: 100%"
                                >
                                    <el-option
                                            v-for="item in accountTypeList"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="级别" prop="collectionLevel">
                                <el-select v-model="inputForm2.collectionLevel"
                                           placeholder="请选择藏品级别"
                                           style="width: 100%"
                                           clearable>
                                    <el-option
                                            v-for="item in leaveList"
                                            :key="item.level"
                                            :label="item.levelName"
                                            :value="item.level">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="出库状态" prop="storeState">
                                <el-select v-model="inputForm2.storeState"
                                           placeholder="请选择出库状态"
                                           style="width: 100%"
                                           clearable>
                                    <el-option
                                            v-for="item in storeStateList2"
                                            :key="item.state"
                                            :label="item.stateName"
                                            :value="item.state">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="申请状态" prop="applicationState">
                                <el-select v-model="inputForm2.applicationState"
                                           placeholder="请选择入申请状态"
                                           style="width: 100%"
                                           clearable>
                                    <el-option
                                            v-for="item in applicationStateList2.filter(item=>{ return item.state != 1})"
                                            :key="item.state"
                                            :label="item.stateName"
                                            :value="item.state">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="藏品名称" prop="collectionName">
                                <el-input
                                        v-model.trim="inputForm2.collectionName"
                                        placeholder="请输入藏品名称(限300字)"
                                        maxlength="300" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="出库时间" prop="outDate" class="dateBox2">
                                <el-date-picker
                                        v-model="inputForm2.outDate"
                                        type="daterange"
                                        clearable
                                        style="width: 100%;"
                                        value-format="yyyy-MM-dd"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item class="dateBox2" label-width="20px">
                                <el-button size="small" type="primary" icon="el-icon-search" @click="getDataList(1,1)">
                                    查询
                                </el-button>
                                <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置
                                </el-button>
                            </el-form-item>
                        </el-form>

                        <div class="bg-white">
                            <div class="text_right">
                                <el-button size="small"
                                           v-show="hasPermissionButton(`collection:business:${typePage}:batchExpor`)"
                                           @click="exportData(1)">
                                    <i class="icon-piliangdaochu iconfont buIcon"/>批量导出
                                </el-button>
                            </div>
                            <el-table
                                    :data="dataList"
                                    size="small"
                                    v-loading="loading"
                                    ref="multipleTable2"
                                    height="calc(100vh - 385px)"
                                    class="table"
                                    :row-key="'id'"
                                    :header-cell-style="{background:'#F9DFDF'}"
                                    @selection-change="selectionChangeHandle"
                            >
                                <el-table-column :reserve-selection="true" type="selection" width="50"/>
                                <el-table-column label="序号" type="index" width="50"></el-table-column>
                                <el-table-column prop="name" label="图标" width="120px">
                                    <template slot-scope="scope">
                                        <div class="flex_a_c">
                                            <div v-if="scope.row.carded == 0"
                                                 class="icon-wenwukapian iconfont listIcon"></div>
                                            <el-image
                                                    style="width: 36px; height: 36px"
                                                    :src="scope.row.picMasterViewUrl"
                                                    :fit="'cover'"
                                                    :preview-src-list="[scope.row.picMasterViewUrl]"
                                            >
                                                <div slot="error" class="image-slot">
                                                    <el-image
                                                            style="width: 36px; height: 36px"
                                                            :src="require('@/assets/img/default.png')"
                                                            :fit="'cover'">
                                                    </el-image>
                                                </div>
                                            </el-image>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="collectionName" label="藏品名称"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="helpNum" label="辅助账编号"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="generalNum" label="总账编号"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="entryState" label="账目归属" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        {{ scope.row.entryState == 1 ? '总账' : '辅助帐' }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="oneTypeName" label="藏品分类"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="collectionLevel" label="级别" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        {{ getlistSelet(scope.row.collectionLevel, leaveList, 'level', 'levelName') }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="collectionType" label="藏品类型" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        {{
                                        getlistSelet(scope.row.collectionType, typeList, 'id', 'collectionTypeName')
                                        }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="integrity" label="完残程度"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="integrityInfo" label="完残情况"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="applicantName" label="出库申请人"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="reason" label="出库原因" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        {{ $dictUtils.getDictLabel("outbound_reason", scope.row.reason, '-') }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="storeState" label="出库状态" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        {{ getlistSelet(scope.row.storeState, storeStateList2, 'state', 'stateName') }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="applicationState" label="申请状态" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        {{
                                        getlistSelet(scope.row.applicationState, applicationStateList2, 'state', 'stateName')
                                        }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="preDate" sortable label="预入库时间" width="150px">
                                    <template slot-scope="scope">
                                        {{ scope.row.preDate | formatDate('YYYY-MM-DD') }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="operatorTime" sortable label="出库/回库时间" width="150px">
                                    <template slot-scope="scope">
                                        {{ scope.row.operatorTime | formatDate('YYYY-MM-DD') }}
                                    </template>
                                </el-table-column>
                                <el-table-column fixed="right" label="操作" width="180">
                                    <template slot-scope="scope">
                                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                                   @click="addData(5, scope.row,scope.$index,4)"
                                                   v-show="hasPermissionButton(`collection:business:${typePage}:detail`)">
                                            出库详情
                                        </el-button>
                                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                                   @click="collDetail(scope.row)"
                                                   v-show="hasPermissionButton(`collection:business:${typePage}:collDetail`)">
                                            藏品详情
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="text_center">
                                <el-pagination
                                        @size-change="sizeChangeHandle"
                                        @current-change="currentChangeHandle"
                                        :current-page="pageNo"
                                        :page-sizes="[10, 20, 50, 100]"
                                        :page-size="pageSize"
                                        :total="total"
                                        background
                                        layout="total, sizes, prev, pager, next, jumper"
                                >
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <record ref="record"></record>
    </div>
</template>

<script>

import record from "@/views/modules/collection/accounts/module/record.vue";

export default {
    name: "recordOut",
    components: {record},
    props: {
        typePage: {
            type: String,
            default: 'recordOut',
        },
    },
    data() {
        return {
            selectTab: '1',
          showList:true,
            tabsList: [
                {
                    name: '出库记录',
                    value: '1',
                },
                {
                    name: '藏品出库台账',
                    value: '2',
                },
            ],
            inputForm: {
                reason: '',
                applicant: '',

                outDate: [],
                outBountStartDate: '',
                outBountEndDate: '',

                storeState: '',
                applicationState: '',
                reviewState: '',
                specificReasons: '',
            },
            applicantList: [],
            applicantLoading: false,
            storeStateList: [],
            storeStateList2: [],
            applicationStateList: [
                {
                    name: '申请中',
                    value: '0',
                },
                {
                    name: '申请驳回',
                    value: '1',
                },
                {
                    name: '申请通过',
                    value: '2',
                },
                {
                    name: '已结束',
                    value: '3',
                },
            ],
            applicationStateList2: [],
            reviewStateList: [
                {
                    name: '审核中',
                    value: '0',
                },
                {
                    name: '审核驳回',
                    value: '1',
                },
                {
                    name: '审核通过',
                    value: '2',
                },
            ],

            inputForm2: {
                accountType: '',
                collectionLevel: '',
                storeState: '',
                applicationState: '',
                applicant: '',
                collectionName: '',

                outDate: [],
                preStartDate: '',
                preEndDate: '',
            },
            archivesBasicDataId: [],
            treeData: [],
            filterText: '',
            treeLoading: false,
            defaultProps: {
                children: 'children',
                label: 'archivesBasicName'
            },
            accountTypeList: [
                {
                    label: '总账',
                    value: '1',
                },
                {
                    label: '辅助账',
                    value: '2',
                },
            ],
            leaveList: [],
            typeList: [],

            loading: false,
            dataList: [],
            dataListSelect: [],
            pageNo: 1,
            pageNo2: 0,
            pageSize: 10,
            total: 0,
            searchRecord: {},
        }
    },

    mounted() {
        this.getAllState()
        this.handleClick()
    },

    methods: {
        // 查询树状数据
        searchTree(type, paNo) {
            this.treeData = [
                {
                    id: '',
                    archivesBasicName: '全部',
                    children: [],
                },
            ]
            this.treeLoading = true
            this.$axios(this.api.collection.selectArchivesBasicData, {}, 'post').then((res) => {
                if (res.status) {
                    this.treeData[0].children = res.data
                    //全选
                    this.$nextTick(() => {
                        if (!type) {
                            this.getTreeId(this.treeData)
                        }
                        this.$refs.tree.setCheckedKeys(this.archivesBasicDataId);
                        this.selRecord('', '', type, paNo)
                    })
                }
                this.treeLoading = false
            })
        },

        //循环取树形控件id
        getTreeId(data) {
            if (data && data.length != 0) {
                data.forEach(item => {
                    this.archivesBasicDataId.push(item.id)
                    if (item.children) {
                        this.getTreeId(item.children)
                    }
                })
            } else {
                return
            }
        },

        getDataList(type, dividePage) {
            if (type == 1) {
                this.pageNo = 1
            }
            if (dividePage == 1) {
                this.$refs['multipleTable' + this.selectTab].clearSelection()
            }
            let searchData
            let fetchUrl = this.api.collection.listOrderRecode
            if (this.selectTab == 1) {
                searchData = JSON.parse(JSON.stringify(this.inputForm))
                if (searchData.outDate && searchData.outDate.length == 2) {
                    searchData.outBountStartDate = searchData.outDate[0]
                    searchData.outBountEndDate = searchData.outDate[1]
                } else {
                    searchData.outBountStartDate = ''
                    searchData.outBountEndDate = ''
                }
                this.$delete(searchData, 'outDate')
            } else {
                fetchUrl = this.api.collection.standingBook
                searchData = JSON.parse(JSON.stringify(this.inputForm2))
                if (searchData.outDate && searchData.outDate.length == 2) {
                    searchData.preStartDate = searchData.outDate[0]
                    searchData.preEndDate = searchData.outDate[1]
                } else {
                    searchData.preStartDate = ''
                    searchData.preEndDate = ''
                }
                this.$delete(searchData, 'outDate')
                let archivesBasicDataId = this.archivesBasicDataId.filter(item => {
                    return item == ''
                })
                if (archivesBasicDataId.length != 0) {
                    archivesBasicDataId = null
                } else {
                    archivesBasicDataId = this.archivesBasicDataId
                }
                this.$set(searchData, 'archivesBasicDataId', archivesBasicDataId)
            }
            this.$set(searchData, 'orderType', 1)
            this.searchRecord = {
                ...searchData,
                current: this.pageNo2 ? this.pageNo2 : this.pageNo,
                size: this.pageSize,
            }
            this.loading = true
            this.$axios(fetchUrl, this.searchRecord, 'post').then((res) => {
                if (res.status) {
                    this.dataList = res.data.records
                    this.$nextTick(() => {
                        // this.$refs.multipleTable.doLayout()
                    })
                    this.total = parseInt(res.data.total)
                    if (this.dataList.length == 0 && this.pageNo > 1) {
                        this.pageNo--
                        this.getDataList()
                    }
                    let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
                    if (listSearch) {
                        this.pageNo = listSearch.pageNo
                        this.pageNo2 = 0
                        sessionStorage.removeItem('listSearch')
                    }
                } else {
                    this.$message.error('查询失败');
                }
                this.loading = false
            })
        },

        handleClick(tab, event) {
            let num = 0
            let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
            if (listSearch) {
                if (listSearch.selectTab == '1') {
                    this.inputForm = listSearch.inputForm
                }
                if (listSearch.selectTab == '2') {
                    this.inputForm2 = listSearch.inputForm
                    this.archivesBasicDataId = listSearch.archivesBasicDataId
                    num = 1
                }
                this.pageSize = listSearch.pageSize
                this.selectTab = listSearch.selectTab
                this.pageNo2 = listSearch.pageNo
            }
            this.$nextTick(() => {
                if (this.pageNo2) {
                    if (this.selectTab == 1) {
                        this.getDataList('', 1)
                    } else {
                        this.searchTree(num, 3)
                    }
                } else {
                    if (this.selectTab == 1) {
                        this.getDataList(1, 1)
                    } else {
                        this.searchTree(num)
                    }
                }
            })
        },

        //列表展示
        getlistSelet(id, data, idName, name) {
            let seleRow = data.filter(item => {
                return item[idName] == id
            })
            if (seleRow.length != 0) {
                return seleRow[0][name]
            }
        },

        exportData() {
            let ids = []
            if (this.dataListSelect.length == 0) {
                this.$message.warning('请至少选择一条数据')
                return
            }

            ids = this.dataListSelect.map(item => {
                if (item) {
                    return item.id
                }
            })
            let data = {
                ids: ids,
            }
            let fetchUrl = this.api.collection.batchOutBoundListOrderExport
            let name = '出库记录列表'
            if (this.selectTab == 2) {
                fetchUrl = this.api.collection.standingBookOutBountExport
                name = '台帐出库记录'
            }
            this.exportExcel(fetchUrl, data, name)
            this.getDataList('', 1)
        },

        addData(num, row, index, type) {
            let listSearch = {
                inputForm: this.inputForm2,
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                selectTab: this.selectTab,
                archivesBasicDataId: this.archivesBasicDataId,
            }
            sessionStorage.setItem('listSearch', JSON.stringify(listSearch))
            this.searchRecord.current = (this.pageNo - 1) * this.pageSize + index + 1
            this.searchRecord.size = 1
            sessionStorage.setItem('registrationSearch', JSON.stringify(this.searchRecord))
            if (num == 5) {
                this.$router.push({
                    path: '/collection/business/outBound/outboundDetails',
                    query: {butType: num, id: row.id, switch: type,}
                })
            } else {
                this.$router.push({
                    path: '/collection/business/outBound/addOutbound',
                    query: {butType: num, id: row.id, switch: type,}
                })
            }
        },

        //审核记录
        record(row) {
            this.$refs.record.init(row.id,'',16)
        },

        deleteDate(row) {
            this.$confirm(`您是否确认删除数据?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios(this.api.collection.collectionwarehouseorderhandledRemoveById + row.id, {}, 'delete').then(data => {
                    if (data && data.status) {
                        this.$message.success('删除成功')
                        this.getDataList('', 1)
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            })
        },

        collDetail(row) {
            let listSearch = {
                inputForm: this.inputForm2,
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                selectTab: this.selectTab,
                archivesBasicDataId: this.archivesBasicDataId,
            }
            sessionStorage.setItem('listSearch', JSON.stringify(listSearch))
            this.$router.push({
                path: '/collection/accounts/addCollection',
                query: {
                    butType: 3,
                    id: row.snapshotId,
                    archivesBasicDataId: row.archivesBasicDataId,
                    typePage: 'InOroutStorage',
                    see: 1
                }
            })
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            this.dataListSelect = val
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.getDataList(1, 1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.pageNo = val;
            this.getDataList('', '');
        },

        //重置
        resetting() {
            if (this.selectTab == 1) {
                this.$refs.inputForm.resetFields();
            } else {
                this.$refs.inputForm2.resetFields();
            }
            this.getDataList(1, 1)
        },

        getAllState() {
            this.$axios(this.api.collection.listOutBount, {}, 'get').then(data => {
                if (data.status) {
                    this.storeStateList = data.data
                }
            })
            this.$axios(this.api.collection.listItemOutBount, {}, 'get').then(data => {
                if (data.status) {
                    this.storeStateList2 = data.data
                }
            })
            this.$axios(this.api.collection.listLevelSelect).then(data => {
                if (data.status) {
                    this.leaveList = data.data
                }
            })
            this.$axios(this.api.collection.listSelect).then(data => {
                if (data.status) {
                    this.typeList = data.data
                }
            })
            this.$axios(this.api.collection.listItemOrderApplication, {}, 'get').then(data => {
                if (data.status) {
                    this.applicationStateList2 = data.data
                }
            })
        },

        // 选择档案分类
        selRecord(data, checked, type, paNo) {
            //该节点所对应的对象、树目前的选中状态对象
            if (checked) {
                this.archivesBasicDataId = []
                this.archivesBasicDataId = checked.checkedNodes.map(item => {
                    return item.id
                })
            }
            if (this.archivesBasicDataId.length == 0) return
            if (paNo) {
                this.getDataList('', 1)
            } else {
                this.getDataList(1, 1)
            }
        },

        //筛选树的数据
        filterTreeData() {
            this.$refs.tree.filter(this.filterText);
        },

        filterNode(value, data) {
            if (!value) return true;
            return data.archivesBasicName.indexOf(value) !== -1;
        },

        //边打边搜
        remoteMethod(query) {
            this.applicantLoading = true;
            this.$axios(this.api.collection.searchUserByLike, {username: query}, 'get').then(data => {
                if (data.status) {
                    this.applicantList = data.data
                    this.applicantLoading = false;
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
/*去掉tabs底部的下划线*/
.tabsCls >>> .el-tabs__nav-wrap::after {
    position: static !important;
}

.buIcon {
    font-size: 12px;
    margin-right: 5px;
}

.noData {
    line-height: calc(100vh - 250px);
}

.itembaf {
    background: #FFFFFF;
    padding: 20px !important;
}

.searchForm {
    .el-form-item--small.el-form-item {
        display: inline-block;
        width: 33.33%;
    }

    .dateBox2.el-form-item--small.el-form-item {
        display: inline-block;
        width: 50%;
    }
}

.leftHeighe {
  height: calc(100vh - 224px);
  overflow-y: auto;
}

.treeDateCls {
  overflow-y: auto !important;
  height: calc(100% - 65px);
}
</style>
